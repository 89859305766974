define("discourse/plugins/discourse-follow/discourse/components/follow-button", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _decorators, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("user", "currentUser"), _dec2 = (0, _decorators.default)("isFollowed", "canFollow"), _dec3 = (0, _decorators.default)("isFollowed", "canFollow"), (_obj = {
    loading: false,
    isFollowed: (0, _computed.alias)("user.is_followed"),
    canFollow: (0, _computed.alias)("user.can_follow"),

    showButton(user, currentUser) {
      if (!currentUser) {
        return false;
      }

      if (currentUser.id === user.id) {
        return false;
      }

      if (user.suspended) {
        return false;
      }

      if (user.staged) {
        return false;
      }

      if (user.id < 1) {
        // bot
        return false;
      }

      return true;
    },

    labelKey(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "follow.unfollow_button_label";
      } else {
        return "follow.follow_button_label";
      }
    },

    icon(isFollowed, canFollow) {
      if (isFollowed && canFollow) {
        return "user-times";
      } else {
        return "user-plus";
      }
    },

    toggleFollow() {
      const type = this.isFollowed ? "DELETE" : "PUT";
      this.set("loading", true);
      (0, _ajax.ajax)("/follow/".concat(this.user.username, ".json"), {
        type
      }).then(() => {
        this.set("isFollowed", !this.isFollowed);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set("loading", false);
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "showButton", [_dec], Object.getOwnPropertyDescriptor(_obj, "showButton"), _obj), _applyDecoratedDescriptor(_obj, "labelKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "labelKey"), _obj), _applyDecoratedDescriptor(_obj, "icon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "icon"), _obj), _applyDecoratedDescriptor(_obj, "toggleFollow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleFollow"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-feed-post", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ["user-stream-item", "item"]
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];

  var _default = _component.default.extend((_dec = (0, _decorators.observes)(...preferences.map(p => "user.".concat(p))), (_obj = {
    _updatePreferences() {
      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }

      preferences.forEach(p => {
        this.user.set("custom_fields.".concat(p), this.user[p]);
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "_updatePreferences", [_dec], Object.getOwnPropertyDescriptor(_obj, "_updatePreferences"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-post-stream", ["exports", "discourse/components/user-stream"], function (_exports, _userStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _userStream.default;
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-statistic", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("context"), (_obj = {
    classNames: "follow-statistic",

    init() {
      this._super();

      this.set("tagName", this.get("isCard") ? "h3" : "div");
    },

    didInsertElement() {
      Ember.run.scheduleOnce("afterRender", () => {
        let parent = this.get("isCard") ? ".card-content .metadata" : ".user-main .secondary dl";
        this.$().prependTo(parent);
      });
    },

    isCard(context) {
      return context === "card";
    }

  }, (_applyDecoratedDescriptor(_obj, "isCard", [_dec], Object.getOwnPropertyDescriptor(_obj, "isCard"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/components/follow-users-list", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/computed", "@ember/object/computed"], function (_exports, _component, _decorators, _computed, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("type", "viewingSelf"), (_obj = {
    hasUsers: (0, _computed2.notEmpty)("users"),
    viewingSelf: (0, _computed.propertyEqual)("user.username", "currentUser.username"),

    noneMessage(type, viewingSelf) {
      let key = viewingSelf ? "none" : "none_other";
      return "user.".concat(type, ".").concat(key);
    }

  }, (_applyDecoratedDescriptor(_obj, "noneMessage", [_dec], Object.getOwnPropertyDescriptor(_obj, "noneMessage"), _obj)), _obj)));

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-additional-buttons/follow-button-container"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"user\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"follow-button\",\"user\"]]","moduleName":"javascripts/discourse/connectors/user-card-additional-buttons/follow-button-container","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-metadata/follow-statistics-user-card"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"total_following\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"user.following.label\"],null]],[10,1],[14,0,\"value\"],[12],[1,[33,1,[\"total_following\"]]],[13],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"total_followers\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"user.followers.label\"],null]],[10,1],[14,0,\"value\"],[12],[1,[33,1,[\"total_followers\"]]],[13],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"user\",\"i18n\"]]","moduleName":"javascripts/discourse/connectors/user-card-metadata/follow-statistics-user-card","isStrictMode":false});
define("discourse/plugins/discourse-follow/discourse/connectors/user-card-metadata/follow-statistics-user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender(_, component) {
      return component.siteSettings.follow_show_statistics_on_profile;
    }

  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-main-nav/follow-user-container"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"can_see_network_tab\"]],[[[1,\"  \"],[6,[39,2],null,[[\"route\"],[\"follow\"]],[[\"default\"],[[[[1,[28,[35,3],[\"users\"],null]],[1,[28,[35,4],[\"user.follow_nav\"],null]]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"model\",\"link-to\",\"d-icon\",\"i18n\"]]","moduleName":"javascripts/discourse/connectors/user-main-nav/follow-user-container","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"user\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"follow-notification-preferences\",\"model\"]]","moduleName":"javascripts/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container","isStrictMode":false});
define("discourse/plugins/discourse-follow/discourse/connectors/user-preferences-notifications/follow-notification-preferences-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender(_, component) {
      return component.siteSettings.follow_notifications_enabled;
    }

  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/user-profile-controls/follow-button-container"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"user\"],[[33,1]]]]],[1,\"\\n\"]],[],false,[\"follow-button\",\"model\"]]","moduleName":"javascripts/discourse/connectors/user-profile-controls/follow-button-container","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-profile-secondary/follow-statistics-user"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"total_following\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"label\",\"total\",\"context\"],[\"user.following.label\",[33,1,[\"total_following\"]],\"profile\"]]]],[1,\"\\n\"]],[]],null],[41,[33,1,[\"total_followers\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"label\",\"total\",\"context\"],[\"user.followers.label\",[33,1,[\"total_followers\"]],\"profile\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"model\",\"follow-statistic\"]]","moduleName":"javascripts/discourse/connectors/user-profile-secondary/follow-statistics-user","isStrictMode":false});
define("discourse/plugins/discourse-follow/discourse/connectors/user-profile-secondary/follow-statistics-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender(_, component) {
      return component.siteSettings.follow_show_statistics_on_profile;
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/controllers/feed", ["exports", "@ember/controller", "discourse/lib/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    viewingSelf: (0, _computed.propertyEqual)("model.user.id", "currentUser.id")
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/controllers/follow", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({});

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/initializers/follow-initializer", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "follow-plugin-initializer",

    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.10", api => {
        const currentUser = api.getCurrentUser();

        if (!currentUser) {
          return;
        }

        api.replaceIcon("notification.following", "discourse-follow-new-follower");
        api.replaceIcon("notification.following_created_topic", "discourse-follow-new-topic");
        api.replaceIcon("notification.following_replied", "discourse-follow-new-reply"); // workaround to make core save custom fields when changing
        // preferences

        api.modifyClass("controller:preferences/notifications", {
          pluginId: "discourse-follow-notification-preference",
          actions: {
            save() {
              if (!this.saveAttrNames.includes("custom_fields")) {
                this.saveAttrNames.push("custom_fields");
              }

              this._super(...arguments);
            }

          }
        });
      });
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/models/post-stream", ["exports", "discourse-common/utils/decorators", "discourse/models/rest", "@ember/object/computed", "rsvp", "discourse/models/topic", "discourse/models/user", "discourse/lib/ajax", "@ember/object"], function (_exports, _decorators, _rest, _computed, _rsvp, _topic, _user, _ajax, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // this class implements an interface similar to the `UserStream` class in core
  // (app/models/user-stream.js) so we can use it with the `{{user-stream}}`
  // component (in core as well) which expects a `UserStream` instance.
  var _default = _rest.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("loading", "content.length"), (_obj = {
    loading: false,
    itemsLoaded: 0,
    canLoadMore: true,
    lastPostCreatedAt: (0, _computed.reads)("content.lastObject.created_at"),

    _initialize() {
      this.set("content", []);
    },

    noContent(loading, length) {
      return !loading && length === 0;
    },

    findItems() {
      if (!this.canLoadMore || this.loading) {
        return _rsvp.Promise.resolve();
      }

      this.set("loading", true);
      const data = {};

      if (this.lastPostCreatedAt) {
        data.created_before = this.lastPostCreatedAt;
      }

      return (0, _ajax.ajax)("/follow/posts/".concat(this.user.username), {
        data
      }).then(content => {
        const posts = content.posts.map(post => {
          post.user = _user.default.create(post.user);
          post.topic.category_id = post.category_id;
          delete post.category_id;
          post.topic = _topic.default.create(post.topic);
          return _object.default.create(post);
        });
        return {
          posts,
          hasMore: content.extras.has_more
        };
      }).then(_ref => {
        let {
          posts,
          hasMore
        } = _ref;
        this.content.addObjects(posts);
        this.set("itemsLoaded", this.content.length);
        this.set("canLoadMore", hasMore);
      }).finally(() => {
        this.set("loading", false);
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "_initialize", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initialize"), _obj), _applyDecoratedDescriptor(_obj, "noContent", [_dec2], Object.getOwnPropertyDescriptor(_obj, "noContent"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/feed", ["exports", "discourse/routes/discourse", "../models/post-stream"], function (_exports, _discourse, _postStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model() {
      return _postStream.default.create({
        user: this.modelFor("user")
      });
    },

    afterModel(model) {
      return model.findItems();
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/follow-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel() {
      var _this$currentUser;

      const model = this.modelFor("user");
      const canSeeFollowers = model.can_see_followers;
      const canSeeFollowing = model.can_see_following;

      if (((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id) === model.id) {
        this.replaceWith("feed");
      } else if (canSeeFollowing) {
        this.replaceWith("following");
      } else if (canSeeFollowers) {
        this.replaceWith("followers");
      } else {
        this.replaceWith("user");
      }
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/followers", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/u/".concat(this.paramsFor("user").username, "/follow/followers"));
    },

    setupController(controller, model) {
      controller.setProperties({
        users: model,
        user: this.modelFor("user")
      });
    }

  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/routes/following", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/u/".concat(this.paramsFor("user").username, "/follow/following"));
    },

    setupController(controller, model) {
      const user = this.modelFor("user");
      controller.setProperties({
        users: model,
        user
      });
    }

  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/follow-button"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[28,[37,1],[[33,2],[33,3]],null],[[[1,\"  \"],[1,[28,[35,4],null,[[\"label\",\"icon\",\"disabled\",\"action\"],[[33,5],[33,6],[33,7],[28,[37,8],[[30,0],\"toggleFollow\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"showButton\",\"canFollow\",\"d-button\",\"labelKey\",\"icon\",\"loading\",\"action\"]]","moduleName":"javascripts/discourse/templates/components/follow-button","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/follow-feed-post"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"clearfix info\"],[12],[1,\"\\n  \"],[10,3],[15,6,[33,0,[\"user\",\"userUrl\"]]],[15,\"data-user-card\",[33,0,[\"user\",\"username\"]]],[14,0,\"avatar-link\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[33,0,[\"user\"]]],[[\"imageSize\",\"extraClasses\",\"ignoreTitle\"],[\"large\",\"actor\",true]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"time\"],[12],[1,[28,[35,2],[[33,0,[\"created_at\"]]],[[\"leaveAgo\"],[\"true\"]]]],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"item\"],[[33,0]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"stream-topic-details\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"stream-topic-title\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"title\"],[12],[1,\"\\n        \"],[10,3],[15,6,[28,[37,4],[[33,0,[\"url\"]]],null]],[12],[1,[28,[35,5],[[33,0,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"group-post-category\"],[12],[1,[28,[35,6],[[33,0,[\"topic\",\"category\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"excerpt\"],[12],[1,\"\\n\"],[41,[33,0,[\"expandedExcerpt\"]],[[[1,\"    \"],[1,[28,[35,5],[[33,0,[\"expandedExcerpt\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,5],[[33,0,[\"excerpt\"]]],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"post\",\"avatar\",\"format-date\",\"expand-post\",\"get-url\",\"html-safe\",\"category-link\",\"if\"]]","moduleName":"javascripts/discourse/templates/components/follow-feed-post","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/follow-notification-preferences"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"control-group follow-notifications\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.follow.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.allow_people_to_follow_me\",[33,2,[\"allow_people_to_follow_me\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed\",[33,2,[\"notify_me_when_followed\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_followed_user_when_followed\",[33,2,[\"notify_followed_user_when_followed\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_replies\",[33,2,[\"notify_me_when_followed_replies\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_creates_topic\",[33,2,[\"notify_me_when_followed_creates_topic\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"i18n\",\"preference-checkbox\",\"user\"]]","moduleName":"javascripts/discourse/templates/components/follow-notification-preferences","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/follow-post-stream"] = Ember.HTMLBars.template({"id":null,"block":"[[[42,[28,[37,1],[[28,[37,1],[[33,2,[\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"post\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"post\"],false,[\"each\",\"-track-array\",\"stream\",\"follow-feed-post\"]]","moduleName":"javascripts/discourse/templates/components/follow-post-stream","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/follow-statistic"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,1],[14,0,\"desc\"],[12],[1,[28,[35,2],[[33,3]],null]],[13],[10,1],[12],[1,[34,4]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"dt\"],[12],[1,[28,[35,2],[[33,3]],null]],[13],[10,\"dd\"],[12],[1,[34,4]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isCard\",\"i18n\",\"label\",\"total\"]]","moduleName":"javascripts/discourse/templates/components/follow-statistic","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/follow-users-list"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"follow-users\"],[12],[1,\"\\n\"],[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"      \"],[1,[28,[35,5],null,[[\"user\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,6],[[33,7]],[[\"username\"],[[33,8,[\"username\"]]]]]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"user\"],false,[\"if\",\"hasUsers\",\"each\",\"-track-array\",\"users\",\"user-info\",\"i18n\",\"noneMessage\",\"user\"]]","moduleName":"javascripts/discourse/templates/components/follow-users-list","isStrictMode":false});
Ember.TEMPLATES["javascripts/feed"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"noContent\"]],[[[41,[33,2],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,3],[\"user.feed.empty_feed_you\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,3],[\"user.feed.empty_feed_other\"],[[\"username\"],[[33,4,[\"username\"]]]]]],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"stream\"],[[33,1]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"model\",\"viewingSelf\",\"i18n\",\"user\",\"follow-post-stream\"]]","moduleName":"javascripts/discourse/templates/feed","isStrictMode":false});
Ember.TEMPLATES["javascripts/follow"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"pageClass\",\"class\",\"scrollTop\"],[\"user-follow\",\"user-secondary-navigation\",false]],[[\"default\"],[[[[6,[39,1],null,[[\"class\",\"desktopClass\"],[\"activity-nav\",\"action-list follow-list nav-stacked\"]],[[\"default\"],[[[[41,[28,[37,3],[[33,4,[\"id\"]],[33,5,[\"id\"]]],null],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[6,[39,6],null,[[\"route\"],[\"feed\"]],[[\"default\"],[[[[1,[28,[35,7],[\"user.feed.label\"],null]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[33,4,[\"can_see_following\"]],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[6,[39,6],null,[[\"route\"],[\"following\"]],[[\"default\"],[[[[1,[28,[35,7],[\"user.following.label\"],null]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,8],[[33,4,[\"can_see_followers\"]],[33,4,[\"allow_people_to_follow_me\"]]],null],[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[6,[39,6],null,[[\"route\"],[\"followers\"]],[[\"default\"],[[[[1,[28,[35,7],[\"user.followers.label\"],null]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[]]]]],[1,\"\\n\"],[10,\"section\"],[14,0,\"user-content user-follows-tab\"],[12],[1,\"\\n  \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"d-section\",\"mobile-nav\",\"if\",\"eq\",\"model\",\"currentUser\",\"link-to\",\"i18n\",\"and\",\"component\",\"-outlet\"]]","moduleName":"javascripts/discourse/templates/follow","isStrictMode":false});
Ember.TEMPLATES["javascripts/followers"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"users\",\"type\",\"user\"],[[33,1],\"followers\",[33,2]]]]],[1,\"\\n\"]],[],false,[\"follow-users-list\",\"users\",\"user\"]]","moduleName":"javascripts/discourse/templates/followers","isStrictMode":false});
Ember.TEMPLATES["javascripts/following"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"users\",\"type\",\"user\"],[[33,1],\"following\",[33,2]]]]],[1,\"\\n\"]],[],false,[\"follow-users-list\",\"users\",\"user\"]]","moduleName":"javascripts/discourse/templates/following","isStrictMode":false});
define("discourse/plugins/discourse-follow/discourse/users-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    resource: "user",

    map() {
      this.route("follow", {
        path: "/follow",
        resetNamespace: true
      }, function () {
        this.route("feed", {
          path: "/feed",
          resetNamespace: true
        });
        this.route("followers", {
          path: "/followers",
          resetNamespace: true
        });
        this.route("following", {
          path: "/following",
          resetNamespace: true
        });
      });
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-follow/discourse/widgets/following-notification-item", ["discourse/widgets/widget", "discourse/widgets/default-notification-item", "discourse/lib/url", "I18n"], function (_widget, _defaultNotificationItem, _url, _I18n) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "following-notification-item", {
    description() {
      return _I18n.default.t("notifications.following_description");
    },

    url(data) {
      return (0, _url.userPath)(data.display_username);
    }

  });
});

